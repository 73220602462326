import React, { Component } from 'react'
//import PropTypes from 'prop-types'
//import withMediaProps from '../decorators/with-media-props'

//TODO maybe refactor to an extension of the original PlayPause control in react-media-rpops
//now it's copy pasta - only the _handlePlayPause changes
class PlayPauseCallback extends Component {
    constructor(props) {
        super(props);

        this._handlePlayPause = this._handlePlayPause.bind(this);
    }

    shouldComponentUpdate(prevProps) {
        return this.props.playing !== prevProps.isPlaying
    }

    _handlePlayPause() {
        //this.props.media.playPause()

        //we do not interact with the media directly, but push the state upwards
        //so that only one media is playing at the same time
        this.props.onPlayPause(this.props.segmentID);
    }

    render() {
        const { className, style, playing } = this.props
        return (
            <button
                type="button"
                className={className}
                style={style}
                onClick={this._handlePlayPause}
            >
                {playing ? '❚❚' : '▶'}
            </button>
        )
    }
}

export default PlayPauseCallback;
