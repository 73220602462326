import React, { Component } from 'react';

export default class TrackDisplayer extends Component {
    render() {
        return (
            <>
                <button onClick={this.props.onPrevTrack} disabled={this.props.currentTrackIndex === 0}>«</button>
                <strong>{this.props.currentTrackIndex} / {this.props.totalTracksCount}</strong>:
                {this.props.currentTrackName}
                <button onClick={this.props.onNextTrack} disabled={this.props.currentTrackIndex === this.props.totalTracksCount}>»</button>
            </>
        );
    }
}
