import React, { Component } from 'react';

//using reacttable component https://react-table.js.org/#/story/readme
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { SegmentType, MarkType } from './MarkingData';

import { Menu, Item, Separator } from 'react-contexify';
import { contextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

//lets you get a range of numbers or characters by passing the upper and lower bounds
//thanks to https://stackoverflow.com/questions/3895478/does-javascript-have-a-method-like-range-to-generate-a-range-within-the-supp
function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}

export default class TextDisplayer extends Component {

    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleNullify = this.handleNullify.bind(this);
        this.handleSwitchToHeader = this.handleSwitchToHeader.bind(this);
        this.handleSwitchToNormal = this.handleSwitchToNormal.bind(this);
        this.handleSwitchToIgnore = this.handleSwitchToIgnore.bind(this);
    }

    /**
     * Generates column headers based on segments in the marking data.
     */
    get columnHeaders() {
        const columns = [];
        const audioFormattingCSS = { fontSize: "xx-small" };
        const audioWidth = 60;

        this.props.markingData.baseSegments.forEach(segment => {
            let textSegment = null;
            let audioSegment = null;

            //set the variables - depending on if we have text, audio or both
            if (segment.segmentType === SegmentType.TEXT) {
                textSegment = segment;
                if (segment.hasLinkedAudio) {
                    audioSegment = segment.linkedAudio;
                }
            } else if (segment.segmentType === SegmentType.AUDIO) {
                audioSegment = segment;
            } else throw new Error('Unknown segment type.');

            //create columns accordinly
            //if the text has linked audio, add three columns (audio start time, text, audio end time)
            //if just audio - only two columns
            //if just text - only one columns

            if (audioSegment !== null) {
                columns.push({
                    id: 'audioStart_' + audioSegment.segmentID,
                    displayedSegment: audioSegment,
                    leftPos: true,
                    Header: '☛',
                    style: audioFormattingCSS,
                    width: audioWidth,
                    accessor: (row) => this.getAudioPos(row, audioSegment, true)
                });
            }

            if (textSegment !== null) {
                columns.push({
                    id: 'textBit_' + textSegment.segmentID,
                    displayedSegment: textSegment,
                    Header: 'Text',
                    style: { whiteSpace: 'unset' }, //this CSS disables ellipsis of longer texts (thanks https://stackoverflow.com/questions/48853012/how-to-do-word-wrap-for-data-using-react-table )
                    accessor: (row) => this.getTextBit(row, textSegment, 500)
                });
            }

            if (audioSegment !== null) {
                columns.push({
                    id: 'audioEnd_' + audioSegment.segmentID,
                    displayedSegment: audioSegment,
                    width: audioWidth,
                    style: audioFormattingCSS,
                    Header: '☚',
                    accessor: (row) => this.getAudioPos(row, audioSegment)
                });
            }

            //in any case, also add one control column per base segment (a column with buttons)
            /*
            columns.push({
                id: 'buttons_' + segment.segmentID,
                width: audioWidth,
                Header: '🖍',
                Cell: row =>
                    (
                        <>
                            {(row.original !== 0) ?
                                (<button onClick={() => this.handleDelete(row.original - 1, segment)}>🗙</button>) : null}
                            <button>…</button>
                        </>
                    )
            });
            */
        });

        return columns;
    }

    /**
     * 
     * @param {number} markIndex the index of the mark (i.e. row in the table)
     * @param {Segment} textSegment The text segment to load the part from.
     * @returns {number} the position of the given mark (or zero, or target text length, if markIndex is out of bounds)
     */
    getPositionFloored(markIndex, textSegment) {
        const markingData = this.props.markingData;

        if (markIndex < 0) return 0;

        let mark;
        if (markIndex >= markingData.maxCount || (mark = markingData.getMarkForSegment(markIndex, textSegment)) === undefined)
            return this.props["text" + textSegment.segmentID].length;

        //TODO - for multifile mode - here implement (right now we are expecting single file mode)

        if (mark.positionEnd === null) {
            //the mark is set, but is null = empty - go upwards
            return this.getPositionFloored(markIndex - 1, textSegment);
        }

        return mark.positionEnd;
    }

    /**
     * Returns the piece of text that coresponds 
     * to the position of the given mark and one mark before the given mark.
     * 
     * @param {number} markIndexTo The index of the mark ending the desired part.
     * @param {Segment} textSegment The text segment to load the part from.
     */
    getTextBit(markIndexTo, textSegment, ellipsis = null) {
        const textIndex = textSegment.segmentID;
        const text = this.props["text" + textIndex];

        if (!text) //text not (yet) set
            return "...";

        const fromPos = this.getPositionFloored(markIndexTo - 1, textSegment);
        const toPos = this.getPositionFloored(markIndexTo, textSegment);

        if (ellipsis !== null && (fromPos + ellipsis) < toPos)
            return text.slice(fromPos, fromPos + ellipsis) + "...";

        return text.slice(fromPos, toPos);
    }

    /**
     * Returns the time corresponds 
     * to the position of the given mark (or one mark before the given mark).
     * 
     * @param {number} markIndex The index of the mark ending (or beginning) the desired part.
     * @param {Segment} segment The index of the audio segment to load the data from.
     * @param {boolean} fromPos 
     *                          If true, the beginning time will be returned. 
     *                          If false, the ending time will be returned.
     */
    getAudioPos(markIndex, segment, fromPos = false) {
        let markIndexReal = markIndex;

        //if the right side ("toPos") is a null value, return "--:--" (even on the left side - i.e. even if fromPos == true)
        let audioMark = this.props.markingData.getMarkForSegment(markIndexReal, segment);
        if (audioMark && audioMark.positionEnd === null) {
            return "--:--";
        }

        if (fromPos) { //we are returning the left side (i.e. the previous mark)
            //simply get the previous one (index - 1)
            markIndexReal--;
            audioMark = this.props.markingData.getMarkForSegment(markIndexReal, segment);

            //unless the previous mark is null - then we have to traverse up until we hit a non-null mark
            if (audioMark && audioMark.positionEnd === null) {
                while (markIndexReal > 1) {
                    markIndexReal--;

                    audioMark = this.props.markingData.getMarkForSegment(markIndexReal, segment);
                    if (!audioMark || audioMark.positionEnd !== null)
                        break;
                }
            }
        }

        if (markIndexReal < 0) return "00:00";

        if (!audioMark)
            return '';

        //TODO - for multifile mode - here implement

        //format seconds as time
        //thanks https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
        const date = new Date(null);
        date.setSeconds(audioMark.positionEnd);
        //return date.toISOString().substr(11, 8); //HH:MM:SS
        return date.toISOString().substr(14, 5); //MM:SS
    }

    clickedText(rowIndex, textSegment) {
        const selection = window.getSelection();
        const clickPos = selection.focusOffset;
        const preceedingPositionValue = this.getPositionFloored(rowIndex - 1, textSegment);
        const newPositionValue = preceedingPositionValue + clickPos;

        //TODO - for multifile mode - here implement
        this.props.markListener(textSegment, rowIndex, newPositionValue);
    }

    clickedAudio(rowIndex, audioSegment) {
        if (rowIndex === -1) {
            this.props.moveListener(audioSegment, 0);
            return;
        }

        const targetMark = this.props.markingData.getMarkForSegment(rowIndex, audioSegment);

        this.props.moveListener(audioSegment, targetMark.positionEnd, targetMark.fileID);
    }

    handleDelete({ event, props }) {
        this.props.deleteListener(props.rowIndex, props.displayedSegment);
    }
    handleNullify({ event, props }) {
        this.props.nullifyListener(props.rowIndex, props.displayedSegment);
    }
    handleSwitchToNormal({ event, props }) {
        this.props.typeChangeListener(props.rowIndex, props.displayedSegment, MarkType.NORMAL);
    }
    handleSwitchToIgnore({ event, props }) {
        this.props.typeChangeListener(props.rowIndex, props.displayedSegment, MarkType.IGNORE);
    }
    handleSwitchToHeader({ event, props }) {
        this.props.typeChangeListener(props.rowIndex, props.displayedSegment, MarkType.HEADER);
    }

    render() {
        //add one item to the markings to display the rest of the text
        const marksIndicesForIteration = range(this.props.markingData.maxCount + 1);

        return (
            <>
                <Menu id='menu_id'>
                    <Item onClick={this.handleDelete}
                    >Delete item (join with next line)</Item>
                    <Item onClick={this.handleNullify}
                    >Nullify item (replace with null mark)</Item>
                    <Separator />
                    <Item onClick={this.handleSwitchToNormal}>Normal</Item>
                    <Item onClick={this.handleSwitchToIgnore}>Ignore</Item>
                    <Item onClick={this.handleSwitchToHeader}>Header</Item>
                </Menu>

                <ReactTable
                    data={marksIndicesForIteration}
                    columns={this.columnHeaders}
                    defaultPageSize={50}
                    showPaginationTop={true}
                    page={this.props.page}
                    onPageChange={this.props.pageChangeListener} // Called when the page index is changed by the user

                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo !== undefined && column.displayedSegment) {
                                    if (column.displayedSegment.segmentType === SegmentType.TEXT)
                                        this.clickedText(rowInfo.index, column.displayedSegment);
                                    else if (column.displayedSegment.segmentType === SegmentType.AUDIO) {
                                        if (column.leftPos)
                                            this.clickedAudio(rowInfo.index - 1, column.displayedSegment);
                                        else
                                            this.clickedAudio(rowInfo.index, column.displayedSegment);
                                    }
                                }

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            },
                            onContextMenu: (e) => {
                                // always prevent default behavior
                                e.preventDefault();

                                if (rowInfo !== undefined) {
                                    contextMenu.show({
                                        id: 'menu_id',
                                        event: e,
                                        props: {
                                            rowIndex: rowInfo.index,
                                            displayedSegment: column.displayedSegment
                                        }
                                    });
                                }
                            },
                            style: ({
                                backgroundColor:
                                    rowInfo === undefined || this.props.markingData.getMarkType(rowInfo.index) === MarkType.NORMAL
                                        ? "white" :
                                        "#a08c48"
                            })

                        }
                    }
                    }
                />
            </>
        );
    }
}
