//please see FORMAT_DESIGN.md
//for documentation 

export const MarkType = Object.freeze({
    NORMAL: 1,

    IGNORE: 10,
    HEADER: 11
});

export const MarkMode = Object.freeze({
    NORMAL: 1,
});


class Mark {
    constructor(fileID, positionEnd) {
        this.fileID = fileID;
        this.positionEnd = positionEnd;
    }
}

export const SegmentType = Object.freeze({
    TEXT: 51,
    AUDIO: 52
});

export class Segment {
    constructor(segmentID, segmentType, linkedAudio = null) {
        this._segmentID = segmentID;
        this._segmentType = segmentType;

        if (linkedAudio !== null)
            this.linkedAudio = linkedAudio;
    }

    get segmentID() {
        return this._segmentID;
    }

    get segmentType() {
        return this._segmentType;
    }

    get hasLinkedAudio() {
        return !!this._linkedAudio;
    }

    get linkedAudio() {
        return this._linkedAudio;
    }

    set linkedAudio(value) {
        if (!typeof value instanceof Segment)
            throw TypeError('Only segments can be linked.');
        if (this.segmentType !== SegmentType.TEXT)
            throw Error('Only text segments can be linked to other segments.');
        if (value.segmentType !== SegmentType.AUDIO)
            throw Error('Only audio segments can be linked to by other segments.');

        this._linkedAudio = value;
    }
}

export class MarkingData {
    constructor(segments, storedData = null) {
        this._ROW_FLAGS_COLUMN = "ROW_FLAGS_COLUMN_";
        this.segments = segments;

        if (storedData !== null) {
            if (!isNaN(storedData[0][0])) {
                const newData = {};
                for (let key in storedData) {
                    newData[key] = [];
                    storedData[key].forEach(element => {
                        newData[key].push(new Mark(0, element));
                    });
                }
                this._marks = newData;
            }
            else this._marks = storedData;
        }
    }

    serialize() {
        return this._marks;
    }

    _filterBaseSegments(segments) {
        const segmentsForRemoval = {}; //hashset

        segments.forEach(element => {
            if (element.hasLinkedAudio)
                segmentsForRemoval[element.linkedAudio.segmentID] = true;
        });

        const baseSegments = [];

        segments.forEach(element => {
            if (!segmentsForRemoval[element.segmentID])
                baseSegments.push(element);
        });

        return baseSegments;
    }

    insertMark(markingMode, segment, rowIndex, fileID, value) {
        if (markingMode === MarkMode.NORMAL) {
            this._marks[segment.segmentID].splice(rowIndex, 0, new Mark(fileID, value));
        }
        else throw new Error("Invalid marking mode value.");
    }

    insertMarkDouble(markingMode, textSegment, rowIndex, fileID, value, audioFileID, audioValue) {
        this.insertMark(markingMode, textSegment, rowIndex, fileID, value);
        this.insertMark(markingMode, textSegment.linkedAudio, rowIndex, audioFileID, audioValue);
    }

    getMarkType(rowIndex) {
        if (!this._marks[this._ROW_FLAGS_COLUMN]) //add the column if not set
            this._marks[this._ROW_FLAGS_COLUMN] = {};

        //MarkType.NORMAL is the default value when not defined
        return this._marks[this._ROW_FLAGS_COLUMN][rowIndex] || MarkType.NORMAL;
    }

    setMarkType(rowIndex, value) {
        if (value !== MarkType.NORMAL && value !== MarkType.IGNORE && value !== MarkType.HEADER)
            throw new Error("Invalid mark type.");

        this._marks[this._ROW_FLAGS_COLUMN][rowIndex] = value;
    }

    nullifyMark(segment, rowIndex) {
        this.getMarkForSegment(rowIndex, segment).positionEnd = null;
        //this._marks[segment.segmentID].splice(rowIndex, 1, null);
    }
    deleteMark(segment, rowIndex) {
        this._marks[segment.segmentID].splice(rowIndex, 1);
        if (segment.hasLinkedAudio)
            this._marks[segment.linkedAudio.segmentID].splice(rowIndex, 1);
    }

    /**
     * @param {number} markIndex the index of the mark (i.e. row in the table)
     * @param {Segment} segment The text or audio segment get the value of.
     * @returns {Mark}
     */
    getMarkForSegment(markIndex, segment) {
        return this._marks[segment.segmentID][markIndex];
    }

    /**
     * All segments.
     * @returns {Segment[]} 
     */
    get segments() {
        return this._segments;
    }

    set segments(value) {
        if (!value)
            throw Error('No segments set.');

        this._segments = value;
        this._baseSegmentsCache = this._filterBaseSegments(value);

        //(re)set the marks field
        //marks field is an object, with the property name being the SegmentID
        //the value being an array of Marks (initially empty)
        const newEmptyMarks = {};
        newEmptyMarks[this._ROW_FLAGS_COLUMN] = [];
        value.forEach(element => {
            //if (!({}).hasOwnProperty.call(element, value)) //skip any inherited properites
            //    continue;

            //also check if no ID is used twice
            if (element.segmentID in newEmptyMarks)
                throw Error('Segments must have unique ID.');

            newEmptyMarks[element.segmentID] = [];
        });
        this._marks = newEmptyMarks;
    }

    /**
     * All segments except audios that are linked to
     * (those are accessible from their text segments via linkedAudio property).
     * @returns {Segment[]} 
     */
    get baseSegments() {
        return this._baseSegmentsCache;
    }

    /**
     * The count of marks in the largest segment.
     */
    get maxCount() {
        let max = 0;
        for (const segmentID in this._marks) {
            if (segmentID === this._ROW_FLAGS_COLUMN)
                continue;

            const marksArray = this._marks[segmentID];
            max = Math.max(max, marksArray.length);
        };
        return max;
    }

}
