import React, { Component } from 'react';
import './App.css';
//import AudioManager from './AudioManager';
import AudioManagerRMP from './AudioManagerRMP';
//import MarkingManager from './MarkingManager';
import TextDisplayer from './TextDisplayer';
import { MarkMode, MarkingData, Segment, SegmentType } from './MarkingData';

//allows storing objects to local storage
//thanks https://stackoverflow.com/questions/2010892/storing-objects-in-html5-localstorage
Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
}

Storage.prototype.getObject = function (key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markingData: this.fetchStoredMarkingData(),
      audioFileNames: {
        0: //hardcoded audio segment ID (English)
          //this magic produces a list of files with zero padded numbering 01 to 75  
          [...Array(75).keys()].map(i => "quovadis_" + ("0" + (i + 1)).slice(-2) + "_sienkiewicz.ogg"),
        2: //hardcoded audio segment ID (Polish)
          // "Henryk Sienkiewicz Quo vadis, czyta Jan Englert - odc. ##.mp3" for 01 to 50
          [...Array(50).keys()].map(i => "Henryk Sienkiewicz Quo vadis, czyta Jan Englert - odc. " + ("0" + (i + 1)).slice(-2) + ".mp3")

      },
      pageInTextDisplayer: this.fetchStoredPage(),
      initialSeek: this.fetchStoredSeek([0, 2]),//TODO remove hardcoded IDs

      playingIndex: null //which audio is playing (0 or 1) or null if none is playing
    }

    this.moveAudio = this.moveAudio.bind(this);
    this.mark = this.mark.bind(this);
    this.delete = this.delete.bind(this);
    this.nullify = this.nullify.bind(this);
    this.changePage = this.changePage.bind(this);
    this.playPause = this.playPause.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);

    this.fetchFiles();

    this.segmentsRef = {};
    this.segmentsRef[0] = React.createRef();
    this.segmentsRef[2] = React.createRef();
  }

  /**
   * Preload files used for development.
   */
  fetchFiles() {
    //this.loadDataToState("/quovadis_01_sienkiewicz.ogg.json", "peaks", true);
    //this.loadDataToState("/up01.mp3.json", "peaksSecond", true);
    this.loadDataToState("/simplePeaks.json", "peaks", true);
    this.loadDataToState("/simplePeaks.json", "peaksSecond", true);

    this.loadDataToState("/quovadisataleti00siengoog_djvu_edited.txt", "text");
    this.loadDataToState("/quo-vadis.txt", "textSecond");
  }

  fetchStoredPage() {
    const storedData = localStorage.getItem('textDisplayerPage');
    if (storedData) { //if stored, convert to number
      return storedData ? (+storedData) : 0;
    }
  }

  fetchStoredSeek(audioSegmentsID) {
    const result = {};
    audioSegmentsID.forEach(segmentID => {
      const storedData = localStorage.getObject('audioseek' + segmentID);
      if (storedData) //stored value
        result[segmentID] = { index: storedData[0], pos: storedData[1] };
      else //default value
        result[segmentID] = { index: 0, pos: 0 };
    });
    return result;
  }

  changePage(newPage) {
    this.setState({ pageInTextDisplayer: newPage });
    localStorage.setItem("textDisplayerPage", newPage);
  }

  onTimeUpdate(segmentID, activeAudioFileIndex, currentTime) {
    localStorage.setObject("audioseek" + segmentID, [activeAudioFileIndex, currentTime]);
  }

  fetchStoredMarkingData() {
    const storedData = localStorage.getObject('markingDataStorage');

    const segmentAudioEN = new Segment(0, SegmentType.AUDIO);
    const segmentTextEN = new Segment(1, SegmentType.TEXT, segmentAudioEN);
    const segmentAudioPL = new Segment(2, SegmentType.AUDIO);
    const segmentTextPL = new Segment(3, SegmentType.TEXT, segmentAudioPL);

    if (storedData) {
      return new MarkingData(
        [
          segmentAudioEN,
          segmentTextEN,
          segmentAudioPL,
          segmentTextPL
        ], storedData
      );
    } else {
      return new MarkingData(
        [
          segmentAudioEN,
          segmentTextEN,
          segmentAudioPL,
          segmentTextPL
        ]
      );
    }
  }

  /**
   * 
   * @param {MarkingManager} newMarkingData 
   * @returns newMarkingData unchanged (this is to allow convenient use in setState)
   */
  storeMarkingData(newMarkingData) {
    localStorage.setObject('markingDataStorage', newMarkingData.serialize());
    return newMarkingData;
  }

  loadDataToState = (url, targetStateVar, parseAsJSON = false) => {
    fetch(url)
      .then(function (response) {
        // console.log(url + " -> " + response.ok);
        if (response.ok) {
          return response.text();
        }
        throw new Error('Error loading file from ' + url);
      })
      .then(function (data) {
        if (!parseAsJSON)
          this.setState({ [targetStateVar]: data });
        else
          this.setState({ [targetStateVar]: JSON.parse(data) });
      }.bind(this))
      .catch(function (err) {
        console.log("failed to load ", url, err.message);
      });
  }

  /**
   * Sets the position of the audio player corresponding to the given segment.
   * @param {Segment} audioSegment the segment whose player to change position in
   * @param {number} newPosition the position in the target file
   * @param {number} [fileIndex] the target file (defaults to current file)
   */
  moveAudio(audioSegment, newPosition, fileIndex = null) {
    const audioManager = this.segmentsRef[audioSegment.segmentID].current;
    audioManager.moveTo(newPosition, fileIndex);

    const newSeek = Object.assign(this.state.initialSeek, { [audioSegment.segmentID]: audioSegment });
    this.setState({ initialSeek: newSeek });

    //do not play if not playing, but switch if playing another audio
    if (this.state.playingIndex !== null && this.state.playingIndex !== audioSegment.segmentID) {
      this.setState({ playingIndex: audioSegment.segmentID });
    }
  }

  typeChange(rowIndex, segment, newType) {
    if (rowIndex === undefined)
      throw new Error("Invalid row index.");

    //TODO see note in mark() - this is bad practice of modifying the state directly

    const newMarkingData = this.state.markingData;
    //currently, the type is set per row, so we don't need the segment ID
    newMarkingData.setMarkType(rowIndex, newType);
    this.setState({ markingData: this.storeMarkingData(newMarkingData) });
  }


  mark(textSegment, rowIndex, newPositionValue) {
    //to keep react's law of immutability, clone the marking data
    //note this is not a deep clone, so the values will be probably propagated anyway
    //const newMarkingData = Object.assign(new MarkingData(), this.state.markingData);

    //TODO it seems we have a bad design here - react does not like deep states
    //does the MarkingData have to be cut open into various props in the TextDisplayer?

    const newMarkingData = this.state.markingData;
    const HARDCODED_TEXT_FILEID = 0;
    const HARDCODED_MARKING_MODE = MarkMode.NORMAL;

    //TODO - for multifile text mode - here implement - now hardcoding fileID=0
    if (!textSegment.hasLinkedAudio) {
      newMarkingData.insertMark(HARDCODED_MARKING_MODE, textSegment, rowIndex, HARDCODED_TEXT_FILEID, newPositionValue);
    } else {

      const linkedAudioManager = this.segmentsRef[textSegment.linkedAudio.segmentID].current;

      const audioFileID = linkedAudioManager.currentFileIndex;
      const audioPos = linkedAudioManager.currentTime;

      newMarkingData.insertMarkDouble(HARDCODED_MARKING_MODE, textSegment, rowIndex, HARDCODED_TEXT_FILEID, newPositionValue, audioFileID, audioPos);

      //switch audio
      if (this.state.playingIndex === textSegment.linkedAudio.segmentID) {
        this.switchNext();
      }
    }

    this.setState({ markingData: this.storeMarkingData(newMarkingData) });
  }

  delete(rowIndex, segment) {
    //TODO see note in mark() - this is bad practice of modifying the state directly
    const newMarkingData = this.state.markingData;
    newMarkingData.deleteMark(segment, rowIndex);
    this.setState({ markingData: this.storeMarkingData(newMarkingData) });
  }
  nullify(rowIndex, segment) {
    //TODO see note in mark() - this is bad practice of modifying the state directly
    const newMarkingData = this.state.markingData;
    newMarkingData.nullifyMark(segment, rowIndex);
    this.setState({ markingData: this.storeMarkingData(newMarkingData) });
  }

  render() {
    return (
      <div className="App">
        <div className="navbar">
          <AudioManagerRMP
            segmentID={0}
            single={false}
            audioFiles={this.state.audioFileNames[0]}

            playing={this.state.playingIndex === 0}
            initialPos={this.state.initialSeek[0].pos}
            initialIndex={this.state.initialSeek[0].index}

            onTimeUpdate={this.onTimeUpdate}
            markListener={this.mark}
            playPauseListener={this.playPause}
            ref={this.segmentsRef[0]}
          />
          <AudioManagerRMP
            segmentID={2}
            single={false}
            audioFiles={this.state.audioFileNames[2]}

            playing={this.state.playingIndex === 2}
            initialPos={this.state.initialSeek[2].pos}
            initialIndex={this.state.initialSeek[2].index}

            onTimeUpdate={this.onTimeUpdate}
            markListener={this.mark}
            playPauseListener={this.playPause}
            ref={this.segmentsRef[2]}
          />
        </div>
        <div className="main">
          <TextDisplayer
            page={this.state.pageInTextDisplayer}
            pageChangeListener={this.changePage}

            text1={this.state.text} text3={this.state.textSecond}
            markingData={this.state.markingData}
            markListener={this.mark}
            moveListener={this.moveAudio}
            typeChangeListener={this.typeChange}
            nullifyListener={this.nullify}
            deleteListener={this.delete} />

        </div>
      </div >
    );
    //disable Marking Manager for now
    //        <MarkingManager markingData={this.state.markingData} />
  }

  /** If playing, pause current audio and play the next one.  */
  switchNext() {
    //TODO make it work generally (get rid of hardcoded segment IDs)

    if (this.state.playingIndex === null) { //nothing playing now
      //do nothing
    } else if (this.state.playingIndex === 0) //first playing
      this.setState({ playingIndex: 2 }); //start the next one
    else if (this.state.playingIndex === 2) //second playing
      this.setState({ playingIndex: 0 }); //start the first one
  }

  playPause(requestingSegmentID) {
    if (this.state.playingIndex === requestingSegmentID) //if playing already, pause
      this.setState({ playingIndex: null });
    else //else start
      this.setState({ playingIndex: requestingSegmentID });
  }
}

export default App;
