import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { Media, Player, controls } from 'react-media-player'
import PlayPauseCallback from './PlayPauseCallback';
import TrackDisplayer from './TrackDisplayer';

const {
    CurrentTime,
    SeekBar,
    Duration
} = controls


//uses react-media-player
export default class AudioManagerRMP extends Component {
    state = {
        onLoadPos: this.props.initialPos,
        activeAudioFileIndex: this.props.initialIndex,
        switchingFileHack: false
    };

    constructor(props) {
        super(props);

        this._mediaRef = React.createRef();

        this._seekIntial = this._seekIntial.bind(this);
        this._onTimeUpdateHack = this._onTimeUpdateHack.bind(this);

        this._prevTrack = this._prevTrack.bind(this);
        this._nextTrack = this._nextTrack.bind(this);
    }

    _prevTrack() {
        if ((this.state.activeAudioFileIndex - 1) >= 0)
            this.setState({ activeAudioFileIndex: this.state.activeAudioFileIndex - 1 });
    }
    _nextTrack() {
        if ((this.state.activeAudioFileIndex + 1) < this.props.audioFiles.length)
            this.setState({ activeAudioFileIndex: this.state.activeAudioFileIndex + 1 });
    }

    get currentFileIndex() {
        return this.state.activeAudioFileIndex;
    }

    get currentTime() {
        return this._mediaRef.current.state.currentTime;
    }

    /**
    * 
    * @param {number} newPosition the position in the target file
    * @param {number} [fileIndex] the target file (defaults to current file)
    */
    moveTo(newPosition, fileIndex = null) {
        //in some cases (i.e. when the audioplayer is playing a different file)
        //the seekTo value gets overriden on the consequent play
        //
        //Therefore, we store the desired seek in state.
        if (fileIndex !== null && (this.state.activeAudioFileIndex !== fileIndex)) {
            //file changed
            this.setState({ activeAudioFileIndex: fileIndex, onLoadPos: newPosition, switchingFileHack: true });
        } else if (!this.props.playing) {
            //in paused, also 
            //this.setState({ onLoadPos: newPosition });
        }

        if (this.currentTime !== newPosition)
            this._mediaRef.current.seekTo(newPosition);
    }

    _seekIntial() {
        //this allows seeking to the middle of other files
        //even though the audio player forces seek to 0:0 on load
        if (this.state.onLoadPos !== null) {
            this._mediaRef.current.seekTo(this.state.onLoadPos);
        }
    }

    _onTimeUpdateHack({ currentTime }) {
        if (this.state.onLoadPos !== null && currentTime !== 0) {
            if (!this.state.switchingFileHack) {
                //do not move the position on play when after manual seek
                //(exclude calls on automatic file start with currentTime !== 0)
                this.setState({ onLoadPos: null });
            } else {
                //the audio player annoyingly switches seek to 0:00 after a different file is loaded,
                //and that happens AFTER "onPlay" call (so we cannot fix in in the onplay)
                //That's what this  switchingFileHack does - seek position to the desired one after first call
                //to onTimeUpdate
                this._seekIntial();
                this.setState({ switchingFileHack: false });
            }
        }
        this.props.onTimeUpdate(this.props.segmentID, this.state.activeAudioFileIndex, currentTime);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.playing !== nextProps.playing ||
            this.state.activeAudioFileIndex !== nextState.activeAudioFileIndex;
    }

    componentDidMount() {
        //when onLoad pos prop was passed, we need to seek after the component mounts
        //(only relevant when the audio is first shown and position is loaded from the storage)
        //(the prop is propagated as the value to state in constructor)
        //(note we may not remove value from the state, because play resets the seek automatically)
        this._seekIntial();
    }
    componentDidUpdate(prevProps) {
        //the prop is only used for changes using jumping or the ones saved into DB
        if (this.props.onLoadPos !== prevProps.onLoadPos ||
            this.props.activeAudioFileIndex !== prevProps.activeAudioFileIndex)
            this.moveTo(this.props.onLoadPos, this.props.activeAudioFileIndex);

        // we need to control the audio using the "playing" prop
        if (this.props.playing !== this._mediaRef.current.isPlaying) {
            if (this.props.playing)
                this._mediaRef.current.play();
            else
                this._mediaRef.current.pause();
        }
        //the position is reset with play - need to seek again
        this._seekIntial();
    }

    render() {
        /*
        TODO create a burger menu:
        <MuteUnmute className="media-control media-control--mute-unmute" />
        <Volume className="media-control media-control--volume" />
    */
        return (
            <Media ref={this._mediaRef}>
                <div className="media">
                    <div className="media-player">
                        <Player
                            src={this.props.audioFiles[this.state.activeAudioFileIndex]}
                            vendor="audio"
                            onProgress={this._seekIntial}
                            onPlay={this._seekIntial}
                            autoPlay={this.props.playing}
                            onTimeUpdate={this._onTimeUpdateHack}
                        />
                    </div>
                    <div className="media-controls">
                        <PlayPauseCallback
                            playing={this.props.playing}
                            onPlayPause={this.props.playPauseListener}
                            segmentID={this.props.segmentID}
                            className="media-control media-control--play-pause" />
                        <CurrentTime className="media-control media-control--current-time" />
                        <SeekBar className="media-control media-control--volume-range" />
                        <Duration className="media-control media-control--duration" />
                        <div>
                            <TrackDisplayer
                                currentTrackIndex={this.state.activeAudioFileIndex}
                                currentTrackName={this.props.audioFiles[this.state.activeAudioFileIndex]}
                                totalTracksCount={this.props.audioFiles.length}
                                onPrevTrack={this._prevTrack}
                                onNextTrack={this._nextTrack} />
                        </div>
                    </div>
                </div>
            </Media>
        )
    };
}